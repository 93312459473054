<template>
  <div class="page-yewuyuan" style="background: #f6f6f6; color: #000">
    <van-nav-bar :title="title" left-arrow @click-left="back" :fixed="true" :z-index="999"></van-nav-bar>
    <div class=" flsx">
      <!-- <span @click="setstiem" style="flex: 1; text-align: center">{{ stime }} ~ {{ etime }}</span> -->
      <van-icon @click="timeshang" name="play" style="transform: rotate(180deg)" />
      <span @click="setstiem">{{ stime }} ~ {{ etime }}</span>

      <van-icon @click="timexia" name="play" />
    </div>
    <div class="flxtop">
      <div class="f1">
        <div class="bjs">收款合计</div>
        <div class="jinr" style="color: red">
          {{ ywyDetailObj.total ? ywyDetailObj.total.toFixed(0) : 0 }}
        </div>
      </div>
      <div class="f1">
        <div class="bjs">自费车</div>
        <div class="jinr">{{ ywyDetailObj.selfFunded ? ywyDetailObj.selfFunded.toFixed(0) : 0 }}</div>
      </div>
      <div class="f1">
        <div class="bjs">事故车</div>
        <div class="jinr">{{ ywyDetailObj.accident ? ywyDetailObj.accident.toFixed(0) : 0 }}</div>
      </div>
      <div class="f1">
        <div class="bjs" style="background-color: #def5fd; color: #4350b3"> 钣金费 </div>
        <div class="jinr">
          {{ ywyDetailObj.bjAmount ? ywyDetailObj.bjAmount.toFixed(0) : 0 }}
        </div>
      </div>
      <div class="f1">
        <div class="bjs" style="background-color: #e2f7e4; color: #16b766"> 应收款 </div>
        <div class="jinr">
          {{ ywyDetailObj.thisMonthReceivable ? ywyDetailObj.thisMonthReceivable.toFixed(0) : 0 }}
        </div>
      </div>
      <div class="f1">
        <div class="bjs" style="background-color: #f7e2e2; color: #f84949"> 欠款 </div>
        <div class="jinr">
          {{ ywyDetailObj.thisMonthDebt ? ywyDetailObj.thisMonthDebt.toFixed(0) : 0 }}
        </div>
      </div>
    </div>
    <van-row class="hedt">
      <van-col span="4">名称</van-col>
      <van-col span="8">本月应收|欠款</van-col>
      <van-col span="4">自费款</van-col>
      <van-col span="4">事故款</van-col>
      <van-col span="4">钣金费</van-col>
    </van-row>
    <div class="pt">
      <div class="mian" v-for="(item, idx) in ywyDetailObj.sellManReportvVOS" :key="idx">
        <van-row class="mian_row">
          <van-col span="4">{{ item.name }}</van-col>
          <van-col span="8" class="flex-center">
            <span>{{ item.thisMonthReceivable ? item.thisMonthReceivable.toFixed(0) : 0 }}</span>
            <span class="lanse">|</span>
            <span class="zuoc cRed">{{ item.thisMonthDebt }}</span>
          </van-col>
          <van-col span="4">{{ item.selfFunded }}</van-col>
          <van-col span="4">{{ item.accident }} </van-col>
          <van-col span="4">{{ item.bjAmount }}</van-col>
        </van-row>
      </div>
    </div>
    <van-popup :close-on-click-overlay="false" v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow = false" @confirm="settiem" v-model="currentDate" type="year-month"
        :title="timename" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
  </div>
</template>

<script>
import { ywyDetail } from "@/api/check";
import moment from "moment";
export default {
  data() {
    return {
      isiOS: false,
      isok: false,
      token: "",
      title: "业务详情",
      currentDate: new Date(),
      key: "",
      gid: this.$route.query.gid,
      name: this.$route.query.name,
      stime: moment().format("YYYY-MM"),
      etime: moment().format("YYYY-MM"),
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2030, 10, 1),
      timeshow: false,
      timename: "选择起始年月",
      loading: false,
      total: 0,
      finished: false,
      ywyDetailObj: {
        accident: 0,
        bjAmount: 0,
        selfFunded: 0,
        sellManReportvVOS: [
          {
            accident: 0,
            bjAmount: 0,
            name: "",
            selfFunded: 0,
            thisMonthDebt: 0,
            thisMonthReceivable: 0,
          },
        ],
        thisMonthDebt: 0,
        thisMonthReceivable: 0,
        total: 0,
      },
    };
  },
  created() {
    this.title = this.name + " 业务详情"
    this.stime = this.$route.query.stime ? this.$route.query.stime : moment().format("YYYY-MM")
    this.etime = this.$route.query.etime ? this.$route.query.etime : moment().format("YYYY-MM")
    //判断是否在微信环境
    this.appSource();
  },
  mounted() {
    // this.getlist();
  },
  methods: {
    getlist() {
      this.acv = 5;
      this.ywyDetailObj.sellManReportvVOS = [];
      let params = {
        name: this.name,
        etime: this.etime,
        stime: this.stime,
        gid: this.gid,
      };
      ywyDetail(params).then((res) => {
        if (res.code == 200) {
          this.ywyDetailObj = res.data;
        }
      });
    },
    setstiem() {
      this.timename = "选择起始年月";
      this.timeshow = true;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    settiem(e) {
      if (this.timename == "选择起始年月") {
        this.stime = moment(e).format("YYYY-MM");
        this.timeshow = false;

        setTimeout(() => {
          this.timename = "选择截止年月";
          this.timeshow = true;
        }, 500);
      } else {
        this.etime = moment(e).format("YYYY-MM");
        this.getlist();
        this.timeshow = false;
      }
    },
    back() {
      this.$router.go(-1);
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              // alert('走了这里' +  _this.token)
              _this.getlist();
              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        // _this.token = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI5OTgwMCIsImV4cCI6MTcyNzg4ODk3NH0.J9GXXlESfYityxPaBwtLBEs9Mil62YnnI27KCqtPnan5YRNFsJ_S3C2D3ae4NdjE1NUqKA6AOKNLfBMup_aW2Q";
        localStorage.setItem("Authorization", _this.token);
        // alert(_this.token)
        _this.getlist();
        console.log("andriod");
      }
    },
    timeshang() {
      this.stime = moment(this.stime)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM");
      this.etime = this.stime;
      this.getlist();
    },
    timexia() {
      this.etime = moment(this.etime)
        .startOf("month")
        .subtract("months", -1)
        .format("YYYY-MM");
      this.stime = this.etime;
      this.getlist();
    },
  },
};
</script>

<style lang="less" scoped>
body {
  background: #f6f6f6;
}

.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.fsdd {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;

  .van-search {
    flex: 1;
  }
}

.lanse {
  color: #d80d0d;
  font-weight: bold;
  font-size: 16px;
  padding: 0 2px;
}

.cRed {
  color: #d80d0d;
}

.zuoc {
  width: 15px;
  display: inline-block;
  text-align: left;
}

.boots {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}

.flsx {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 40px;
  margin-top: 46px;
  background-color: #fff;
}

.flsxsss {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  height: 40px;
  padding: 0 12px;
  margin-top: 46px;
}

.topss {
  line-height: 35px;
  font-size: 17px;
  text-align: center;
  padding: 0 10px;
  background: #fff;
  color: #666;
}

.red {
  color: red;
  font-weight: bold;
}

.hedt {
  // line-height: 35px;

  font-weight: bold;
  font-size: 15px;
  text-align: center;
  padding: 5px 10px;
  background: #fff;

  // padding-top: 46px;
  .sgdiv {}

  .ts {
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #666;
    transform: scale(0.9);
  }
}

.flx {
  display: flex;
  align-items: center;
}

.flxtop {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  padding: 5px 0;
  border-bottom: 1px solid #f5f5f5;
  background-color: #fff;
  padding-top: 10px;
  .f1 {
    flex: 1;

    .bjs {
      background-color: #f5f5f5;
      display: inline-block;
      padding: 0 2px;
      border-radius: 2px;
      line-height: 18px;
    }

    .jinr {
      font-size: 14px;
    }
  }
}

.pt {
  /* padding-top: 46px; */
}

.mian {
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 5px 0;
  background: #fff;
}

.mian_row {
  // line-height: 35px;

  font-size: 14px;
  text-align: center;
  padding: 6px 10px;
  background: #fff;
}

.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;

  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}

.ding {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 45px;
  border-top: 1px solid #dcdcdc;
}

.flex-center {
  padding-right: 10px;
}
</style>